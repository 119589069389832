import {Injectable, OnDestroy, Optional} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, concatMap, filter, first, map, switchMap, take, tap} from 'rxjs/operators';
import {
  Block,
  BlockMutationResult,
  BlockType,
  BucketType,
  CheckboxInput,
  Group,
  Page,
  Partial,
  Participant,
  RadioInput,
  SelectOption
} from '@paperlessio/sdk/api/models';
import {BlockSerializer} from '@paperlessio/sdk/api/serializers';
import {BlockService} from './block.service';
import {ToastService} from '@paperlessio/sdk/api/util';
import {ConnectionService} from '@management/base/connection.service';
import {BlockMutationCollection} from './block-mutation.type';
import {containsPage, createPageBlock, processMutation} from './block-mutation.utils';
import {BucketChannelService} from '@paperlessio/sdk/realtime/bucket-channel';
import {BlockChangeDetectionService} from '@blocks/services/block-change-detection/block-change-detection.service';
import {CalculatedBlockAttributeStore} from '@shared/rex/calculated-block-attribute.store';
import {buildBlockMap, recalculateBlockTree, topologicalBlockSort} from '@blocks/block/block-helpers';

let instance: BlockStore;
let burntSlugs: string[] = [];

@Injectable({providedIn: 'root'})
export class BlockStore implements OnDestroy {
  blocks: BehaviorSubject<Block[]> = new BehaviorSubject<Block[]>(null);
  backupBlocks: Block[] = [];
  bucket_id: number;
  bucket_type: BucketType;

  // Subject to notify participation-flow.store without a circular dependency
  onMutated$ = new Subject<BlockMutationResult>();

  get blocksArray() {
    return this.blocks.getValue() || [];
  }

  get editableBlocks(): Observable<Block[]> {
    return this.blocks.pipe(
      filter(x => !!x),
      map(x => x.filter(a => a.settings.editable.enabled))
    );
  }

  private blockMap: Map<number, Block> = new Map<number, Block>();
  private rootBlocks: Page[] & Group[] = []; // blocks without a parent
  private blockSerializer = new BlockSerializer();
  private processedLocalUuid: string[] = [];
  private sentLocalUuid: string[] = [];
  private subs: Subscription = new Subscription();

  constructor(
    private blockService: BlockService,
    private bucketChannelService: BucketChannelService,
    private connectionService: ConnectionService,
    private blockChangeDetectionService: BlockChangeDetectionService,
    private calculatedBlockAttributeStore: CalculatedBlockAttributeStore,
    @Optional() private toastService?: ToastService
  ) {
    instance = this;
    this.subs.add(this.connectionService.onReconnect.subscribe(this.reconnect));
    this.subscribeToBlockMutations();
  }

  reconnect = () => {
    this.init(this.bucket_type, this.bucket_id, true);
  };

  init(bucket_type: BucketType, bucket_id: number, forceLoad: boolean = false): Observable<Block[]> {
    // TODO: Temporary fix!
    // The whole Block(Mutation) Universe lives in the Submittable world, not using its subclasses
    // Document and FormVersion!
    if (bucket_type === BucketType.FormVersion) {
      bucket_type = BucketType.Submittable;
    }

    if (!forceLoad && bucket_type === this.bucket_type && bucket_id === this.bucket_id) {
      return of(this.blocks.value);
    }

    this.bucket_id = bucket_id;
    this.bucket_type = bucket_type;

    return this.blockService.allFromBucket(bucket_type, bucket_id).pipe(
      tap(blocks => {
        this.calculatedBlockAttributeStore.loadConstantBlockAttributes(blocks);
        this.updateBlocks(blocks);
      }),
      concatMap(this.initBlocks)
    );
  }

  updateBlocks = (blocks: Block[] = this.blocksArray) => {
    this.blockMap = buildBlockMap(blocks);
    this.rootBlocks = recalculateBlockTree(blocks, this.blockMap);
    this.blocks.next([...blocks]);
    burntSlugs = blocks.map(b => b.slug);

    return this;
  };

  sendAndUpdate(blockMutationCollection: BlockMutationCollection) {
    this.blockMap = buildBlockMap(this.rootBlocks);
    this.blocks.next(Array.from(this.blockMap.values()));

    return this.send(blockMutationCollection);
  }

  // updateBlocks = (blocks: Block[] = this.blocksArray) => {
  //   this.recalculateTree(blocks);
  //   this.blocks.next([...blocks]);
  //   burntSlugs = blocks.map(b => b.slug);
  //
  //   return this;
  // };
  //
  // sendAndUpdate(blockMutationCollection: BlockMutationCollection) {
  //   const blockMap = this.blockMap;
  //
  //   blockMap.clear();
  //   this.updateBlockMap(this.rootBlocks);
  //   this.blocks.next(Array.from(blockMap.values()));
  //
  //   return this.send(blockMutationCollection);
  // }

  mutate = (blockMutationResult: BlockMutationResult, forceUpdate = false) => {
    if (!blockMutationResult || this.processedLocalUuid.includes(blockMutationResult.local_uuid)) {
      return;
    }

    const changedBlockIds: number[] = [];
    const skipUpdate = forceUpdate ? false : this.sentLocalUuid.includes(blockMutationResult.local_uuid);

    // add parents of to-be-deleted blocks to change detection before local removal
    changedBlockIds.push(...blockMutationResult.deleted_block_ids.map(id => this.getSync(id)?.parent_id));

    // prevent updating local blocks with info we sent to the server ourselves
    // would lead to ugly typing override because of round-trip latency
    const blocks: Block[] = processMutation(blockMutationResult,
      this.blocksArray, skipUpdate);

    // TODO: Check why the next line was removed
    this.processedLocalUuid.push(blockMutationResult.local_uuid);
    this.updateBlocks(blocks).setBackupBlocks(blocks);

    for (const block of [...blockMutationResult.updated_blocks, ...blockMutationResult.created_blocks]) {
      changedBlockIds.push(block.id, block.parent_id);
    }

    this.onMutated$.next(blockMutationResult);

    this.blockChangeDetectionService.setChangedBlockIds(changedBlockIds);
  };

  mutateError = () => {
    this.updateBlocks(this.backupBlocks.map(savedBlock => this.blockSerializer.fromJson(savedBlock)));
    this.toastService?.error('editor.error.mutate');
    this.blockChangeDetectionService.setChangedBlockIds(this.backupBlocks.map(b => b.id));
    // TODO: SentryHelper.captureError? @scheja

    return EMPTY;
  };

  addBlocks(blocks: Block[]): Observable<BlockMutationResult> {
    const isPartial = blocks[0].bucket_type === BucketType.Partial;
    const bucket_type = isPartial ? blocks[0].bucket_type : this.bucket_type;
    const bucket_id = isPartial ? blocks[0].bucket_id : this.bucket_id;

    const collection = new BlockMutationCollection(bucket_type, bucket_id);

    const parentIds: number[] = blocks.map(block => block.parent?.id).filter(id => !!id);
    this.blockChangeDetectionService.setChangedBlockIds(parentIds);

    blocks.forEach((block, index) => {
      collection.createBlock(block, block.parent, block.position);
    });

    return this.send(collection);
  }

  updateBlock(block: Block, forceUpdate: boolean = false): Observable<BlockMutationResult> {
    return this.send(
      new BlockMutationCollection(this.bucket_type, this.bucket_id).updateBlock(block),
      forceUpdate
    );
  }

  deleteBlock(block: Block): Observable<BlockMutationResult> {
    this.blockChangeDetectionService.setChangedBlockIds([block.parent?.id]);

    return this.send(
      new BlockMutationCollection(this.bucket_type, this.bucket_id).deleteBlock(block)
    );
  }

  deleteSelectOption(block: SelectOption): Observable<BlockMutationResult> {
    this.blockChangeDetectionService.setChangedBlockIds([block.parent?.id]);

    const collection = new BlockMutationCollection(this.bucket_type, this.bucket_id);

    // we must first check if the select option to be deleted is the/a default value
    // if so, we update the parent block first (i.e. remove the default value) and THEN delete the select option
    if (block.parent instanceof CheckboxInput && block.parent.default_value.includes(block.id)) {
      block.parent.default_value.splice(block.parent.default_value.indexOf(block.id), 1);
      collection.updateBlock(block.parent);
    }

    if (block.parent instanceof RadioInput && block.parent.default_value === block.id) {
      block.parent.default_value = null;
      collection.updateBlock(block.parent);
    }

    collection.deleteBlock(block);

    return this.send(collection);
  }

  duplicateBlock(block: Block, parent: Block, position: number): Observable<BlockMutationResult> {
    if (parent) {
      this.blockChangeDetectionService.setChangedBlockIds([parent.id]);
    }

    return this.send(
      new BlockMutationCollection(this.bucket_type, this.bucket_id).duplicateBlock(block, parent, position)
    );
  }

  /**
   * ----
   * This method needs to stay synchronous. It's used here in the store AND in the draggable service, the draggable service is
   * synchronous and a refactoring isn't viable at the moment.
   * ----
   * This duplicates the contents of a partial into a new block.
   * Only pushes the changes into the collection.
   * @param collection The collection to store the mutations
   * @param partialChildren The child blocks of the partial.
   * @param p The partial that should be duplicated
   * @param parent The new parent
   * @param position The position in the new parent where the new blocks should be placed
   */
  duplicatePartialContent(collection: BlockMutationCollection, partialChildren: Block[], p: Partial, parent: Block, position: number) {
    if (parent) {
      this.blockChangeDetectionService.setChangedBlockIds([parent.id]);
    }

    // Find the block without a parent, this is the root block and must be omitted
    const group = partialChildren.find(block => !block.parent_id);

    // Sort the children, filter out the root block and duplicate them
    partialChildren
      .sort((a, b) => a.position - b.position)
      .filter(block => block.parent_id === group.id)
      .forEach((child, index) => collection.duplicateBlock(child, parent, position + index));
    return collection;
  }

  /**
   * This duplicates the contents of a partial into a new block.
   * @param p The partial that should be duplicated
   * @param parent The new parent
   * @param position The position in the new parent where the new blocks should be placed
   */
  duplicatePartialContentAndSend(p: Partial, parent: Block, position: number) {
    if (parent) {
      this.blockChangeDetectionService.setChangedBlockIds([parent.id]);
    }

    const collection = new BlockMutationCollection(this.bucket_type, this.bucket_id);

    return this.blockService.allFromBucket(BucketType.Partial, p.id)
      .pipe(
        filter(v => !!v),
        take(1),
        switchMap(partialChildren => this.send(this.duplicatePartialContent(collection, partialChildren, p, parent, position))),
      );
  }

  moveBlock(block: Block, position: number = block.position, parent: Block = block.parent): Observable<BlockMutationResult> {
    this.blockChangeDetectionService.setChangedBlockIds([block.parent?.id, parent?.id]);

    // TODO: Check if this is really needed.
    block = this.getSync(block.id) || block;

    return this.send(
      new BlockMutationCollection(this.bucket_type, this.bucket_id).moveBlock(block, position, parent)
    );
  }

  get(block_id: number, continuous: boolean = false): Observable<Block> {
    return this.blocks.pipe(
      filter(x => !!x),
      (!continuous ? first() : map(el => el)),
      map(() => this.blockMap.get(block_id))
    );
  }

  getSync(block_id: number): Block {
    return this.blockMap.get(block_id);
  }

  getBlocksSync(block_ids: number[]) {
    return block_ids.map(id => this.blockMap.get(id));
  }

  getParentPageId(block_id: number): number {
    const block: Block = this.blockMap.get(block_id);
    if(!block?.parent_id) {
      return block?.id;
    }
    return this.getParentPageId(block.parent_id);
  }

  hasOwnedBlocks(participant: Participant): boolean {
    return this.blocksArray.some(block => block.block_owner_participant_ids.indexOf(participant.id) > -1);
  }

  // TODO: Refactor to use pages directly
  pages(): Observable<Page[]> {
    return this.blocks.pipe(map(() => this.rootBlocks));
  }

  // simplifies access to root group of partial
  rootGroup(): Observable<Group> {
    return this.blocks.pipe(map(() => this.rootBlocks?.[0]));
  }

  dfsPreOrderTopologicalSort(): void {
    // This method assumes blocks that are already "treeded"
    // TODO: COMMENT /\

    topologicalBlockSort(this.rootBlocks, this.blockMap);
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }

  private subscribeToBlockMutations(): void {
    this.subs.add(this.bucketChannelService.blocksChangedSubject?.pipe(
      tap((blockMutationResult: BlockMutationResult) => this.mutate(blockMutationResult))
    ).subscribe());
  }

  private initBlocks = (blocks: Block[]): Observable<Block[]> => {
    this.setBackupBlocks(blocks);
    return containsPage(blocks, this.bucket_type) ? this.createFirstPage(blocks) : of(blocks);
  };

  private createFirstPage(blocks): Observable<Block[]> {
    const pageBlock = createPageBlock(this.bucket_type, this.bucket_id);
    pageBlock.position = 0;
    return this.addBlocks([pageBlock]).pipe(
      map((blockMutationResult: BlockMutationResult) => [...blockMutationResult.created_blocks,...blocks])
    );
  }

  private recalculateTree(blocks: Block[]): void {
    const blockMap = this.blockMap = new Map<number, Block>();

    for (const block of blocks) {
      block.children = [];

      blockMap.set(block.id, block);
    }

    this.rootBlocks = [];

    for (const block of blocks) {
      const parent = blockMap.get(block.parent_id);

      parent
        ? parent.addChild(block, block.position)
        : this.rootBlocks.push(block as Page);
    }

    this.rootBlocks.sort((a, b) => a.position - b.position);
  }

  private updateBlockMap(blocks: Block[]): void {
    const blockMap = this.blockMap;

    for (const block of blocks) {
      blockMap.set(block.id, block);

      this.updateBlockMap(block.children);
    }
  }

  private setBackupBlocks(blocks: Block[]): void {
    this.backupBlocks = JSON.parse(JSON.stringify(blocks));
  }

  private send(blockMutationCollection: BlockMutationCollection, forceUpdate: boolean = false): Observable<BlockMutationResult> {
    // ignore changes coming form the server
    this.sentLocalUuid.push(blockMutationCollection.local_uuid);
    return this.blockService.mutate(blockMutationCollection).pipe(
      catchError(this.mutateError),
      tap(blockMutationResult => this.mutate(blockMutationResult, forceUpdate)));
  }
}

// Generate a new unique slug for a new block of a given type
// This works by counting existing blocks of this type and incrementing the number
// e.g. "text1", "text2", "image1", "image2"
export function nextSlugForBlockType(type: BlockType): string {
  // count existing blocks of this type
  const existingBlocks = instance?.blocksArray?.filter(b => b.type === type) || [];
  let number = existingBlocks.length + 1;
  // build slug with block type and count
  // clean up type: remove "Block::" and "Input::", make lowercase
  const name = type
    .replace('Block::', '')
    .replace('Input::', '')
    .replace('Input', '')
    .toLowerCase();

  let slug = `${name}${number}`;

  // check if slug is already taken
  while (burntSlugs.indexOf(slug) > -1) {
    // if so, increment number and try again
    number++;
    slug = `${name}${number}`;
  }
  burntSlugs.push(slug);
  return `${name}${number}`;
}
